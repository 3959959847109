.header {
    background-color: $color-shopee-ecommerce;
    height: 5.5em;
    display: flex;    
    justify-content: center;
	align-items: center;
	position: relative;
	z-index: 2;
    .container {
        height: 100%;        
        width: 100%;
		max-width: 1200px;
		position: static !important;
		min-height: unset !important;
		@include media('ipad:pro@maximal', down, 'portrait') {
		  max-width: unset;
		  padding: unset;
		}
        .icon {
            display: flex;
            align-items: center;
            height: 100%;
            svg {
                height: calc(5.5em - 2.5em);
                margin: 0 1em;
            }
        }
    }
}