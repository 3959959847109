.checkout.bri {
  .container {
    position: relative;
    min-height: calc(100vh - 5.5em);
    @include media('ipad:standard@minimal', down, 'portrait') {
      min-height: 100vh;
    }
    @include media(51.438em, down) {
      min-height: 100vh;
    }

    .card {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      border-radius: 0.188em;
      box-shadow: 0 0.188em 0.625em rgb($color-black, 0.14);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: calc((30.375em * 0.2) + 30.375em);
      height: calc((29em * 0.2) + 29em);
      padding: 3em 1.8em;
      @include media('phone:potrait@maximal', down, 'portrait') {
        box-shadow: unset;
        background-color: transparent;
        width: 90vw;
        height: 42vh;
        min-height: 24em;
        padding: unset;
      }
      @include media('phone:potrait@minimal', down, 'portrait') {
        height: 65vh;
        min-height: 24em;
      }
      @include media(51.438em, down) {
        box-shadow: unset;
        background-color: transparent;
        height: 85vh;
        padding: unset;
      }
      .descriptive {
        &[data-group='back'] {
          display: none;
          svg {
            color: $color-shopee;
            display: inline-block;
            position: absolute;
            width: 2em;
            height: 2em;
            top: 2.8em;
            left: 1.8em;
            @include media('ipad:standard@minimal', down, 'portrait') {
              width: 1.6em;
              height: 1.6em;
              display: none;
            }
            @include media(51.438em, down) {
              display: none;
            }
            &:hover {
              cursor: pointer;
            }
          }
        }

        &[data-group='title'] {
          text-align: center;
          padding-bottom: 1em;
          @include media('ipad:standard@minimal', down, 'portrait') {
            padding-bottom: 0.75em;
          }
          @include media(51.438em, down) {
            padding-bottom: 0.75em;
          }
          h2 {
            font-size: 1.6em;
            line-height: initial;
            @include media('ipad:standard@minimal', down, 'portrait') {
              font-size: 1.35em;
            }
            @include media(51.438em, down) {
              font-size: 1.35em;
            }
          }
        }

        &[data-group='form'] {
          display: none;
        }

        &[data-group='subtitle'] {
          margin-top: 0.75em;
          text-align: center;
          span {
            font-weight: bold;
            margin-top: 0.3em;
          }
          h4 {
            font-size: 1.2em;
            line-height: initial;
            margin-bottom: 0.5em;
            @include media('ipad:standard@minimal', down, 'portrait') {
              font-size: 1.1em;
            }
            @include media(51.438em, down) {
              font-size: 1.1em;
            }
          }
        }

        &[data-group='otp'] {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          div {
            justify-content: center;
            input {
              text-align: center;
              caret-color: transparent;
              background-color: transparent;
              font-weight: 500;
              font-size: 1.75em;
              height: 2.013em !important;
              width: 2.013em !important;
              outline: none;
              border: none;
              border-bottom: 1px solid rgba($color-black, 0.26);
              margin-right: unset !important;
              @include media('ipad:standard@minimal', down, 'portrait') {
                height: 1.5em !important;
                width: 1.5em !important;
              }
              @include media(51.438em, down) {
                height: 1.7em !important;
                width: 1.7em !important;
              }
              @include media(20em, down, 'portrait') {
                height: 1.2em !important;
                width: 1.3em !important;
              }
              &.active {
                border-bottom: 2.5px solid rgba($color-black, 0.5);
              }
              &:focus {
                border-bottom: 2.5px solid rgba($color-black, 0.5);
              }
              &:not(:last-child) {
                margin-right: 0.8em !important;
              }
            }
          }
          .time {
            transform: translateY(2.5em);
            display: flex;
            flex-direction: column;
            text-align: center;
            @include media('ipad:standard@minimal', down, 'portrait') {
              transform: translateY(1.5em);
            }
            @include media(20em, down, 'portrait') {
              transform: translateY(2em);
            }

            span {
              color: $color-shopee;
              font-size: 1.15em;
              display: none;
              @include media('ipad:standard@minimal', down, 'portrait') {
                font-size: 1.05em;
              }
              @include media(51.438em, down) {
                font-size: 1.05em;
              }
              &.active {
                display: inline-block;
              }
              &:first-child {
                &:active {
                  transform: scale(0.98);
                }
                &:hover {
                  cursor: pointer;
                }
              }
              &:last-child {
                p {
                  color: rgba($color-black, 0.6);
                }
              }
            }
          }
        }

        &[data-group='action'] {
          display: flex;
          align-items: flex-start;
          justify-content: center;
          @include media('ipad:standard@minimal', down, 'portrait') {
            height: 5em;
          }
          @include media('phone:potrait@maximal', down, 'portrait') {
            transform: translateY(-2em);
          }
          @include media('phone:potrait@minimal', down, 'portrait') {
            transform: translateY(-3em);
          }

          button {
            box-shadow: unset;
            background-color: $color-shopee;
            width: 85%;
            padding: 1.3em 0;
            @include media(51.438em, down) {
              padding: 1.1em 0;
            }
            @include media('phone:potrait@maximal', down, 'portrait') {
              padding: 1.1em 0;
            }
            span {
              color: $color-white;
              font-weight: 400;
              font-size: 1.3em;
              text-transform: capitalize;
            }
            &:disabled {
              background-color: rgba($color-black, 0.09);
              span {
                color: rgba($color-black, 0.5);
              }
            }
            &:active {
              transform: scale(0.98);
            }
          }
        }
      }
    }
  }

  .overlay {
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    right: 0;
    width: 0%;
    height: 100%;
    opacity: 0.6;
    transition: none;

    &.loading {
      height: 100% !important;
      background-color: $color-white;
    }

    &.active {
      width: 100%;
    }
  }
}
