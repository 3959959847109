html {
	font-size: 80%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
    color: $color-black;
    font-weight: 400;
    letter-spacing: .025em;
    line-height: 1.750em;
    margin: 0;
    padding: 0;
}

h1 {
    font-size: 2.000em;
}

h2 {
    font-size: 1.800em;
}

h3 {
    font-size: 1.600em;
}

h4 {
    font-size: 1.400em;
}

h5 {
    font-size: 1.100em;
}

h6 {
    font-size: 1.000em;
}

p {
    font-size: .975em;
    font-weight: 400;
}

small {
    letter-spacing: .025em;
}

a,
abbr {
    text-decoration: none;
}

strong {
    font-weight: 700;
}

small {
    font-size: 85%;
}