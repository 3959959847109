
.container {
  padding: 0 10em;
}
.container.layout\:clear {
  padding: 0;
}
.container.layout\:fluid {
  padding: 0 2em;
}

@media (max-width: 75em) and (orientation: landscape) {
  .container {
    padding: 0 2.5%;
  }
  .container.layout\:fluid {
    padding: 0 1%;
  }
}

.card {
  background-color: #fff;
  box-shadow: 0 0 0.75em 0.313em rgba(50, 50, 50, 0.02);
  border-radius: 0.55em;
  display: flex;
  flex-direction: column;
  padding: 1em;
}
.card.height\:auto {
  max-height: auto;
  min-height: auto;
}
.card.height\:fixed {
  max-height: 11em;
  min-height: 11em;
}
.card .descriptive[group='header'] .type[group='icon'] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card .descriptive[group='header'] .type[group='icon'] .text small {
  color: rgba(150, 150, 150, 0.8);
  text-transform: capitalize;
}
.card .descriptive[group='header'] .type[group='icon'] .text h6 {
  text-transform: capitalize;
  font-weight: 600;
}
.card .descriptive[group='header'] .type[group='icon'] .media .icon {
  border-radius: 0.35em;
  background-color: #f1f2f6;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2em;
  height: 2em;
}
.card .descriptive[group='header'] .type[group='icon'] .media .icon svg {
  color: #323232;
  width: 1.2em;
  height: 1.2em;
}
.card .descriptive[group='content'] {
  flex: 1;
}
.card .descriptive[group='footer'] .type[group='line'] .line {
  border-radius: 0.35em;
  background-color: #00beaf;
  width: 1.5em;
  height: 0.15em;
}
.card .descriptive[group='footer'] .type[group='text'] .line {
  border-radius: 0.35em;
  background-color: #554bf5;
  width: 2.3em;
  height: 0.15em;
  margin: 0.2em 0 0.4em 0;
}
.card .descriptive[group='footer'] .type[group='text'] small {
  color: rgba(150, 150, 150, 0.8);
  display: inline-block;
}

