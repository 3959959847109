.loading {
	display: none;

	&.active {
		display: inline;
		z-index: 1;
	}
}

.loading {
	background-color: $color-white;
	box-shadow: 0 0 .875em 0 rgba(0, 0, 0, .11);
	border-radius: 1.250em;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 9vh;
	width: 12vh;
	overflow: hidden;

	.dots {
		left: 45%;
		top: 45%;
		position: relative;
		width: 10px;
		height: 10px;
		border-radius: 5px;
		background-color: $color-shopee;
		color: $color-shopee;
		animation: dotFlashing 1s infinite linear alternate;
		animation-delay: .5s;

		&::before,
		&::after {
			content: '';
			display: inline-block;
			position: absolute;
			top: 0;
		}

		&::before {
			left: -15px;
			width: 10px;
			height: 10px;
			border-radius: 5px;
			background-color: $color-shopee;
			color: $color-shopee;
			animation: dotFlashing 1s infinite alternate;
			animation-delay: 0s;
		}

		&::after {
			left: 15px;
			width: 10px;
			height: 10px;
			border-radius: 5px;
			background-color: $color-shopee;
			color: $color-shopee;
			animation: dotFlashing 1s infinite alternate;
			animation-delay: 1s;
		}
	}
}

@keyframes dotFlashing {
    0% {
        background-color: $color-shopee;
    }

    50%,
    100% {
        background-color: $color-shopee-load;
    }
}