a,
button {
    color: unset;
    font: unset;
    text-decoration: none;
    border: none;
    cursor: pointer;
    display: inline-block;
    margin: 0;
    padding: 0;

    &.button {
        @include shadow(soft);
        @include shape(radius);

        display: inline-block;
        font-size: .775em;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: .035em;
        line-height: unset;
        user-select: none;
        position: relative;
        outline: 0;
        padding: 1.100em 2.500em 1.000em 2.500em;

        span {
            display: inline-block;
            margin-right: .200em;
        }

        &:before {
            @include transform(scale, 0.000);
            
            content: '';
            background-color: $color-black;
            display: block;
            position: absolute;
            z-index: -1;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }

        &:hover {
            @include transition(all, .200s, ease);
        }

        &.color {
            &\:black {
                color: $color-white;
                background-color: $color-black;

                &:hover {
                    background-color: rgba($color-black, .850);
                }
            }

            &\:white {
                color: $color-black;
                background-color: $color-white;

                &:hover {
                    background-color: rgba($color-white, .850);
                }
            }
    
            &\:grey {
                color: $color-white;
                background-color: $color-grey;

                &:hover {
                    background-color: rgba($color-grey, .850);
                }
            }

            &\:lily {
                color: $color-white;
                background-color: $color-lily;

                &:hover {
                    background-color: rgba($color-lily, .850);
                }
            }

            &\:aviana {
                color: $color-white;
                background-color: $color-aviana;

                &:hover {
                    background-color: rgba($color-aviana, .850);
                }
            }

            &\:mint {
                color: $color-white;
                background-color: $color-mint;

                &:hover {
                    background-color: rgba($color-mint, .850);
                }
            }

            &\:melissa {
                color: $color-white;
                background-color: $color-melissa;

                &:hover {
                    background-color: rgba($color-melissa, .850);
                }
            }

            &\:samantha {
                color: $color-white;
                background-color: $color-samantha;

                &:hover {
                    background-color: rgba($color-samantha, .850);
                }
            }

            &\:lara {
                color: $color-black;
                background-color: $color-lara;

                &:hover {
                    background-color: rgba($color-lara, .850);
                }
            }
        }
    }
}