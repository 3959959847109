.error {
  .container {
    padding: 0;
  }
  .container-body {
    max-width: 1200px;
    margin: 0 auto;
    height: calc(100vh - 5.5em);
    display: flex;
    align-items: center;
    justify-content: center;
    .card-wrapper {
      margin: auto;
      .descriptive {
        &[data-group='modal'] {
          .popup {
            z-index: 2;
            .title {
              font-size: 1.25em;
            }
            .img-container {
              display: flex;
              flex-direction: column;
              justify-content: space-around;
            }
            h4 {
              font-size: 1.1em;
            }
          }
        }
      }
      .card {
        padding: unset;
        .card-content {
          width: 28em;
          height: 28em;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 3em 1em;
        h3 {
          text-align: center;
          font-weight: 500;
          &:last-child {
            @include media('phone:potrait@maximal', down, 'portrait') {
              font-weight: 400;
              font-size: 1.2em;
              margin-top: 0.2em;
            }
          }
        }
        h5 {
          text-align: center;
          color: rgb(0, 0, 0, 0.65);
        }
        svg {
          color: $color-samantha;
          margin-bottom: 1.3em;
        }
      }
    }
  }
}
