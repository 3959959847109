.registration.bri {
  .scaffold {
    background-color: #f5f5f5;
    min-height: calc(100vh);
    @include media('ipad:standard@minimal', up, 'portrait') {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
    section {
      &.registration {
        padding: 1em 0;
        height: 100vh;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .descriptive {
          &.show {
            width: 100%;
            height: 100%;
          }
          &[data-group='modal'] {
            position: absolute;
            .popup {
              z-index: 2;
              .title {
                font-size: 1.25em;
              }
              .img-container {
                margin-bottom: 1rem;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
              }
              h4 {
                font-size: 1.1em;
              }
            }
          }
          &[data-group='form'] {
            display: block;
            .form-item {
              .form-item-input {
                display: flex;
                flex-direction: row;
                height: 4em;
                background-color: white;
                position: relative;
                align-items: center;
                padding: 1em;
                @include media('phone:potrait@minimal', down, 'portrait') {
                  padding: 0.8em;
                }

                label,
                input,
                span {
                  font-size: 1.05em;
                  @include media('phone:potrait@minimal', down, 'portrait') {
                    font-size: 0.9em;
                  }
                  @include media('280px', down, 'portrait') {
                    font-size: 0.8em;
                    & + svg {
                      width: 12px;
                      height: 12px;
                    }
                  }
                }
                
                .right-item {
                  font-size: 1.1em;
                  letter-spacing: 0.125em;
                  margin-left: auto;
                  &#cardMask {
                    letter-spacing: 0.175em;
                    transform: translateY(0.22em);
                  }
                  @include media('320px', down, 'portrait') {
                    font-size: 0.8em;
                    letter-spacing: 0.125em;
                  }
                }

                input {
                  outline: none;
                  color: black;
                  &::placeholder {
                    color: rgba(0, 0, 0, 0.09);
                  }
                  padding: 0.25em;
                  border: 0.00625em solid rgba(0, 0, 0, 0.175);
                  height: 75%;
                  margin-left: 0.25em;
                  font-size: 1.1em;
                  letter-spacing: 0.125em;
                  text-align: left;
                  &#cardNumber {
                    width: 3.75em;
                  }
                  &#expDate {
                    width: 2em;
                  }
                  &#phoneNumber {
                    width: 11em;
                  }
                  @include media('320px', down, 'portrait') {
                    font-size: 0.8em;
                    letter-spacing: 0.125em;
                  }
                }
                svg {
                  color: $color-shopee;
                  margin-left: 0.4em;
                }
              }
              .form-item-message {
                font-size: 0.825em;
                padding: 0.6rem 1rem;
                @include media('phone:potrait@minimal', down, 'portrait') {
                  padding: 0.6rem 0.8rem;
                }
                color: rgba(0, 0, 0, 0.65);
                margin-bottom: 0.5em;
                background-color: white;
                transition: all 0.2s ease;
                &.error {
                  transition: all 0.2s ease;
                  color: #ee2c4a;
                  background-color: #fff4f4;
                }
                &:not(:last-child) {
                  border-bottom: 0.00625em solid rgba(0, 0, 0, 0.09);
                }
              }
            }
          }
          &[data-group='slider'] {
            height: 45%;
            min-height: 17.5em;
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            background-color: $color-white;
            border-radius: 1.2em 1.2em 0 0;
            position: fixed;
            left: 50%;
            transform: translate(-50%, 0);
            max-width: 48em;
            bottom: -100vh;
            width: 100%;
            z-index: 2;
            overflow: hidden;
            transition: all 0.8s ease;
            padding: 0.5em 0;

            @include media('ipad:pro@maximal', down, portrait) {
              height: 32.5em;
            }
            @include media('ipad:standard@maximal', down, portrait) {
              height: 30em;
            }
            @include media('phone:potrait@maximal', down, portrait) {
              height: 27.5em;
            }
            @include media('phone:potrait@minimal', down, portrait) {
              height: 25em;
            }

            .slider-item {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-around;

              &[data-group='text'] {
                h4 {
                  color: #282828;
                  @include media('ipad:pro@maximal', down, portrait) {
                    font-size: 1.7em;
                  }
                  @include media('phone:potrait@maximal', down, portrait) {
                    font-size: 1.3em;
                  }
                }
                span {
                  color: rgba(40, 40, 40, 0.9);
                  margin-top: 0.775em;
                  @include media('ipad:pro@maximal', down, portrait) {
                    font-size: 1.3em;
                  }
                  @include media('phone:potrait@maximal', down, portrait) {
                    font-size: 1em;
                  }
                  &.phone-text {
                    margin-top: 0.3em;
                    font-weight: bold;
                  }
                }
              }

              &[data-group='otp-field'] {
                span {
                  margin-top: 1.8em;
                }
                .false {
                  display: none;
                }
                .active {
                  cursor: pointer;
                  color: $color-shopee;
                }
              }

              &[data-group='action'] {
                width: 95%;
                margin: 0 auto;
                border-top: 1px solid rgba(0, 0, 0, 0.03);
                button {
                  background-color: $color-shopee;
                  border-radius: 0.125em;
                  font-weight: 400;
                  width: 100%;
                  padding: 0.95em 0;
                  @include media('ipad:standard@minimal', up, 'portrait') {
                    font-size: 1.2em;
                  }
                  span {
                    color: $color-white;
                  }
                  &:disabled {
                    background-color: rgba($color-black, 0.09);
                    span {
                      @include media(
                        'phone:potrait@maximal',
                        down,
                        'portrait'
                      ) {
                        padding: 0.8em 0;
                      }
                      @include media(
                        'phone:potrait@minimal',
                        down,
                        'portrait'
                      ) {
                        font-size: 0.85em;
                      }
                      color: rgba($color-black, 0.5);
                    }
                  }
                }
              }
            }

            &.active {
              bottom: 0;
            }

            div {
              justify-content: center;
              input {
                text-align: center;
                caret-color: transparent;
                background-color: transparent;
                font-weight: 500;
                font-size: 1.75em;
                height: 2.013em !important;
                width: 2.013em !important;
                outline: none;
                border: none;
                border-bottom: 1px solid rgba($color-black, 0.26);
                margin-right: unset !important;
                @include media('ipad:standard@minimal', down, 'portrait') {
                  height: 1.5em !important;
                  width: 1.5em !important;
                }
                @include media(51.438em, down) {
                  height: 1.7em !important;
                  width: 1.7em !important;
                }
                @include media(20em, down, 'portrait') {
                  height: 1.2em !important;
                  width: 1.3em !important;
                }
                &.active {
                  border-bottom: 2.5px solid rgba($color-black, 0.5);
                }
                &:focus {
                  border-bottom: 2.5px solid rgba($color-black, 0.5);
                }
                &:not(:last-child) {
                  margin-right: 0.8em !important;
                }
              }
            }
            .time {
              transform: translateY(2.5em);
              display: flex;
              flex-direction: column;
              text-align: center;
              @include media('ipad:standard@minimal', down, 'portrait') {
                transform: translateY(1.5em);
              }
              @include media(20em, down, 'portrait') {
                transform: translateY(2em);
              }

              span {
                color: $color-shopee;
                font-size: 1.15em;
                display: none;
                @include media('ipad:standard@minimal', down, 'portrait') {
                  font-size: 1.05em;
                }
                @include media(51.438em, down) {
                  font-size: 1.05em;
                }
                &.active {
                  display: inline-block;
                }
                &:first-child {
                  &:active {
                    transform: scale(0.98);
                  }
                  &:hover {
                    cursor: pointer;
                  }
                }
                &:last-child {
                  p {
                    color: rgba($color-black, 0.6);
                  }
                }
              }
            }
          }
          &[data-group='action'] {
            width: 95%;
            margin: 0 auto;
            border-top: 1px solid rgba(0, 0, 0, 0.03);
            button {
              background-color: $color-shopee;
              border-radius: 0.125em;
              font-weight: 400;
              width: 100%;
              padding: 0.95em 0;
              @include media('ipad:standard@minimal', up, 'portrait') {
                font-size: 1.2em;
              }
              span {
                color: $color-white;
              }
              &:disabled {
                background-color: rgba($color-black, 0.09);
                span {
                  color: rgba($color-black, 0.5);
                }
              }
            }
          }
        }

        .hidden {
          display: none !important;
        }
      }
    }
  }
}
