.mock-bca.container {
  padding: 2rem 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  min-height: calc(100vh - 5.5em);
  background-color: #fff;

  .form-item {
    width: 100%;

    label {
      display: block;
      text-transform: uppercase;
      margin-bottom: 0.5rem;
    }

    input {
      display: block;
      width: 100%;
      box-sizing: border-box;
      padding: 0.375rem 0.75rem;
    }

  }

  .button-group {
    display: inline-flex;
    gap: 0.5rem;
  }

  video {
    width: 100%;
  }
}