*,
*:after,
*:before {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
}

html {
    overflow-x: hidden;
    
    body {
        font-family: 'Lato', Arial, sans-serif;
        width: 100%;
        max-width: 100%;
        margin: 0;
        padding: 0;
    }
}