.popup {
    border-radius: .250em;
    background-color: $color-white;
    box-shadow: 0 -.063em .5em 0 rgb(53 53 53 / 6%);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 36.000em;
    display: none;
    padding: 1.500em 2.000em;

    &.active {
        display: inline;
        z-index: 1;
    }

    h4 {
        font-weight: 300;
        font-family: $font-family;
        font-size: 1.100em;
        line-height: 1.560em;
        text-align: center;

        &.title {
            font-weight: 500;
            font-size: 1.250em;
            margin-bottom: .700em;
        }

        &:last-child {
            font-size: 1.100em;
		}
		& + a {
			width: 100%;
			margin-top: .700em;
		}
    }

    .link {
        background-color: transparent;
        width: 100%;
        border: none;
        outline: none;

        &:hover {
            cursor: pointer;
        }

        h4 {
            color: $color-shopee;
            font-family: $font-family;
            font-weight: 500;
        }
    }

    .action {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding-top: 1.200em;
        margin-top: 1.500em;

        .line {
            background-color: rgba($color-black, .1);
            position: absolute;
            top: 0;
            width: calc(100% + 4.000em);
            height: .100em;
        }

        .button-wrapper {
          display: flex;
          width: 100%;
          button {
            &:first-child{
              margin-left: -2em;
              h4{
                color: unset;
                font-weight: 400;
                margin-bottom: unset;
              }
            }
            &:not(:first-child){
              margin-right: -2em;
            }
          }
          .vertical-line{
            margin-top: -1.2em;
            margin-bottom: -1.5em;
            width: 1px;
            border-left: .1em solid rgba($color-black, .1);
          }
        }

        button {
            background-color: transparent;
            font-family: $font-family;
            font-weight: 500;
            border: none;
            outline: none;
            width: 100%;

            h4 {
                color: $color-shopee;
                font-weight: 500;
                margin-bottom: unset;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }
}