.layer {
	background-color: rgba($color-black, .4);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: none;
	&.active {
		display: inline;
        z-index: 1;
	}
}