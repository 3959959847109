$color-shopee      : #EE4D2D;
$color-shopee-load : #FFF4F4;
$color-yellow      : #EDA500;

$color-black    : #323232;
$color-white    : #FFFFFF;
$color-grey     : #969696;

$color-lily     : #2D41F5;
$color-aviana   : #554BF5;
$color-mint     : #00A0FF;
$color-navy     : #282850;
$color-melissa  : #00BEAF;
$color-samantha : #FA3C64;
$color-lara     : #F5D741;

$color-shopee-ecommerce : #EE4D2D;
$color-shopee-mall      : #D0011B;
$color-shopee-man       : #113366;

$color-state-success : $color-melissa;
$color-state-error   : $color-samantha;
$color-state-caution : $color-lara;