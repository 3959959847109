@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

$font-family : 'Roboto', sans-serif;

body {
	background-color: #f5f5f5;
    .scaffold {
        position: relative;
        max-width: 48.000em;
        min-height: 100vh;
        margin: 0 auto;
	  }

	  h1, h2, h3, h4, h5, p, span, ul, li, button, input {
        font-family: $font-family;
    }

  &.registration{
    &.bri{
      font-family: $font-family;
    }
  }
}
