@import 'public/styles/application/components/pages/registration';

.registration.bca {
  .scaffold {
    min-height: calc(100vh - 5em);
    padding-top: 5em;
    &[data-group='desktop'] {
      min-height: calc(100vh - 10.5em);
    }
    section {
      &.registration {
        padding: 1em;
        .descriptive {
          &[data-group='resultForm'] {
            display: none;
          }

          &[data-group='form'] {
            display: none;
          }

          &[data-group='modal'] {
            .loading {
              display: none;

              &.active {
                display: inline;
                z-index: 1;
              }
            }

            .loading {
              background-color: $color-white;
              box-shadow: 0 0 0.875em 0 rgba(0, 0, 0, 0.11);
              border-radius: 1.25em;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              height: 9vh;
              width: 12vh;
              overflow: hidden;

              .dots {
                left: 45%;
                top: 45%;
                position: relative;
                width: 10px;
                height: 10px;
                border-radius: 5px;
                background-color: $color-shopee;
                color: $color-shopee;
                animation: dotFlashing 1s infinite linear alternate;
                animation-delay: 0.5s;

                &::before,
                &::after {
                  content: '';
                  display: inline-block;
                  position: absolute;
                  top: 0;
                }

                &::before {
                  left: -15px;
                  width: 10px;
                  height: 10px;
                  border-radius: 5px;
                  background-color: $color-shopee;
                  color: $color-shopee;
                  animation: dotFlashing 1s infinite alternate;
                  animation-delay: 0s;
                }

                &::after {
                  left: 15px;
                  width: 10px;
                  height: 10px;
                  border-radius: 5px;
                  background-color: $color-shopee;
                  color: $color-shopee;
                  animation: dotFlashing 1s infinite alternate;
                  animation-delay: 1s;
                }
              }
            }
          }

          &[data-group='iframe'] {
            iframe {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
            .dummy {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              .box {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
                img {
                  width: 20em;
                  height: 20em;
                  object-fit: contain;
                }
                h4,
                h5 {
                  white-space: nowrap;
                }
                h4 {
                  color: rgba($color-black, 0.9);
                }
                h5 {
                  color: rgba($color-black, 0.6);
                  span {
                    font-weight: 700;
                  }
                }
              }
            }
          }

          &[data-group='test'] {
            .test {
              background-color: $color-white;
              box-shadow: 0 0 0.875em 0 rgba(0, 0, 0, 0.11);
              border-radius: 0.25em;
              position: absolute;
              right: 0;
              top: 0;
              padding: 1em;

              button {
                &:first-child {
                  margin-right: 1em;
                }
              }
            }
          }
        }
      }
    }
  }
}

@keyframes dotFlashing {
  0% {
    background-color: $color-shopee;
  }

  50%,
  100% {
    background-color: $color-shopee-load;
  }
}
