.checkout.bca {
  .container {
    position: relative;
    min-height: calc(100vh - 5.5em);
    @include media('ipad:standard@minimal', down, 'portrait') {
      min-height: 100vh;
    }
    @include media(51.438em, down) {
      min-height: 100vh;
    }

    .card {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      border-radius: 0.188em;
      box-shadow: 0 0.188em 0.625em rgb($color-black, 0.14);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: calc((30.375em * 0.2) + 30.375em);
      height: calc((29em * 0.2) + 29em);
      padding: 3em 1.8em;
      @include media('phone:potrait@maximal', down, 'portrait') {
        box-shadow: unset;
        background-color: transparent;
        width: 90vw;
        height: 42vh;
        min-height: 24em;
        padding: unset;
      }
      @include media('phone:potrait@minimal', down, 'portrait') {
        height: 65vh;
        min-height: 24em;
      }
      @include media(51.438em, down) {
        box-shadow: unset;
        background-color: transparent;
        height: 85vh;
        padding: unset;
      }

      .descriptive {
        &[data-group='back'] {
          display: none;
          svg {
            color: $color-shopee;
            display: inline-block;
            position: absolute;
            width: 2em;
            height: 2em;
            top: 2.8em;
            left: 1.8em;
            @include media('ipad:standard@minimal', down, 'portrait') {
              width: 1.6em;
              height: 1.6em;
              display: none;
            }
            @include media(51.438em, down) {
              display: none;
            }

            &:hover {
              cursor: pointer;
            }
          }
        }

        &[data-group='title'] {
          text-align: center;
          padding-bottom: 3.5em;
          @include media('ipad:standard@minimal', down, 'portrait') {
            padding-bottom: 2em;
          }
          @include media(51.438em, down) {
            padding-bottom: 2em;
          }

          h2 {
            font-size: 1.6em;
            line-height: initial;
            @include media('ipad:standard@minimal', down, 'portrait') {
              font-size: 1.35em;
            }
            @include media(51.438em, down) {
              font-size: 1.35em;
            }
          }
        }

        &[data-group='form'] {
          display: none;
        }

        &[data-group='field'] {
          text-align: center;

          h4 {
            font-size: 1.2em;
            line-height: initial;
            margin-bottom: 0.5em;
            @include media('ipad:standard@minimal', down, 'portrait') {
              font-size: 1.1em;
            }
            @include media(51.438em, down) {
              font-size: 1.1em;
            }
          }

          .phone {
            position: relative;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            &:hover {
              cursor: pointer;
            }

            span {
              font-weight: 500;
              font-size: 1.2em;
              @include media('ipad:standard@minimal', down, 'portrait') {
                font-size: 1.1em;
              }
              @include media(51.438em, down) {
                font-size: 1.1em;
              }
            }

            svg {
              width: 1.4em;
              height: 1.4em;
              margin-left: 0.3em;
            }

            ul {
              background-color: $color-white;
              box-shadow: 0 0.113em 0.625em rgba($color-black, 0.2);
              border-radius: 0.188em;
              display: none;
              width: 13.5em;
              position: absolute;
              top: 100%;
              left: 48%;
              transform: translateX(-50%);
              overflow-y: scroll;
              padding: 0.8em;

              &.active {
                display: inline;
              }

              li {
                list-style: none;

                &:not(:last-child) {
                  margin-bottom: 0.65em;
                }

                a {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  &:hover {
                    h5 {
                      color: rgba($color-black, 1);
                    }
                  }

                  h5 {
                    color: rgba($color-black, 0.7);
                    font-size: 1.05em;
                    line-height: initial;
                    padding: 0.2em 0;
                  }

                  svg {
                    color: $color-shopee;
                    stroke-width: 0.25em;
                    width: 1.3em;
                    height: 1.3em;
                  }
                }
              }
            }
          }
        }

        &[data-group='otp'] {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          div {
            justify-content: center;
            input {
              text-align: center;
              caret-color: transparent;
              background-color: transparent;
              font-weight: 500;
              font-size: 1.75em;
              height: 2.013em !important;
              width: 2.013em !important;
              outline: none;
              border: none;
              border-bottom: 1px solid rgba($color-black, 0.26);
              margin-right: unset !important;
              @include media('ipad:standard@minimal', down, 'portrait') {
                height: 1.5em !important;
                width: 1.5em !important;
              }
              @include media(51.438em, down) {
                height: 1.7em !important;
                width: 1.7em !important;
              }
              @include media(20em, down, 'portrait') {
                height: 1.2em !important;
                width: 1.3em !important;
              }
              &.active {
                border-bottom: 2.5px solid rgba($color-black, 0.5);
              }
              &:focus {
                border-bottom: 2.5px solid rgba($color-black, 0.5);
              }
              &:not(:last-child) {
                margin-right: 0.8em !important;
              }
            }
          }
          .time {
            transform: translateY(2.5em);
            display: flex;
            flex-direction: column;
            text-align: center;
            @include media('ipad:standard@minimal', down, 'portrait') {
              transform: translateY(1.5em);
            }
            @include media(20em, down, 'portrait') {
              transform: translateY(2em);
            }

            span {
              color: $color-shopee;
              font-size: 1.15em;
              display: none;
              @include media('ipad:standard@minimal', down, 'portrait') {
                font-size: 1.05em;
              }
              @include media(51.438em, down) {
                font-size: 1.05em;
              }
              &.active {
                display: inline-block;
              }
              &:first-child {
                &:active {
                  transform: scale(0.98);
                }
                &:hover {
                  cursor: pointer;
                }
              }
              &:last-child {
                p {
                  color: rgba($color-black, 0.6);
                }
              }
            }
          }
        }

        &[data-group='action'] {
          display: flex;
          align-items: flex-start;
          justify-content: center;
          @include media('ipad:standard@minimal', down, 'portrait') {
            height: 5em;
          }
          @include media('phone:potrait@maximal', down, 'portrait') {
            transform: translateY(-2em);
          }
          @include media('phone:potrait@minimal', down, 'portrait') {
            transform: translateY(-3em);
          }

          button {
            box-shadow: unset;
            background-color: $color-shopee;
            width: 85%;
            padding: 1.3em 0;
            @include media(51.438em, down) {
              padding: 1.1em 0;
            }
            @include media('phone:potrait@maximal', down, 'portrait') {
              padding: 1.1em 0;
            }
            span {
              color: $color-white;
              font-weight: 400;
              font-size: 1.3em;
              text-transform: capitalize;
            }
            &:disabled {
              background-color: rgba($color-black, 0.09);
              pointer-events: none;
              span {
                color: rgba($color-black, 0.5);
              }
            }
            &:active {
              transform: scale(0.98);
            }
          }
        }
      }
    }
  }

  .overlay {
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    right: 0;
    width: 0%;
    height: 100%;
    opacity: 0.6;
    transition: none;

    &.loading {
      height: 100% !important;
      background-color: $color-white;
    }

    &.active {
      width: 100%;
    }
  }

  .number {
    background-color: $color-white;
    border-radius: 0.35em 0.35em 0 0;
    position: fixed;
    left: 0;
    bottom: -100vh;
    width: 100%;
    z-index: 2;
    overflow: hidden;
    transition: all 0.8s ease;
    @include media(51.438em, down) {
      bottom: -100vh;
    }
    &.active {
      bottom: 0;
    }

    .descriptive {
      &[data-group='head'] {
        position: relative;
        text-align: center;
        padding: 1em 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.03);

        h5 {
          font-weight: 500;
        }

        svg {
          color: rgba(0, 0, 0, 0.4);
          position: absolute;
          right: 1em;
          top: 50%;
          transform: translateY(-50%);
          width: 2.2em;
          height: 2.2em;
        }
      }

      &[data-group='phones'] {
        min-height: 8vh;
        max-height: 25vh;
        overflow-y: scroll;
        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 1em 1.2em;

          &:not(:last-child) {
            border-bottom: 1px solid rgba(0, 0, 0, 0.03);
          }

          h6 {
            font-weight: 500;
          }

          input[type='radio'] {
            display: none;

            &:checked {
              & + label {
                &::before {
                  border-color: $color-shopee;
                }
                &::after {
                  width: 0.6em;
                  height: 0.6em;
                  transform: translate(-50%, -50%);
                  opacity: 1;
                }
              }
            }
          }

          .radio {
            cursor: pointer;
            width: 2em;
            height: 2em;
            position: relative;
            &::before,
            &::after {
              content: '';
              border-radius: 50%;
            }

            &::before {
              background-color: transparent;
              border: 2px solid rgba(0, 0, 0, 0.3);
              transition: border-color 400ms ease;
              width: 1.5em;
              height: 1.5em;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }

            &::after {
              background-color: $color-shopee;
              border: 2px solid $color-shopee;
              width: 0;
              height: 0;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              position: absolute;
              opacity: 0;
            }
          }
        }
      }

      &[data-group='action'] {
        padding: 0.8em;
        border-top: 1px solid rgba(0, 0, 0, 0.03);
        button {
          background-color: $color-shopee;
          border-radius: 0.125em;
          font-weight: 400;
          width: 100%;
          padding: 0.95em 0;
          span {
            color: $color-white;
          }
          &:disabled {
            background-color: rgba($color-black, 0.09);
            pointer-events: none;
            span {
              color: rgba($color-black, 0.5);
            }
          }
        }
      }
    }
  }
}
